import axios from "axios"
import { ElMessage } from "element-plus";
// import { useRouter } from "vue-router";

var instance = axios.create({
  baseURL: '/server', 
  timeout: 10000,
  headers: {
      'Content-Type': 'application/json;charset=UTF-8;',
      'accessToken':sessionStorage.getItem('token')
  }
})


//请求拦截器 
instance.interceptors.request.use((config) => {
  // console.log(config)
  const token = sessionStorage.getItem('token');
  token && (config.headers.Authorization = token)
  if (config.method === 'POST') {
      config.data = JSON.stringify(config.data);
  }
  return config;
}, (error) =>
// 对请求错误做些什么
Promise.reject(error));

// 响应拦截器
instance.interceptors.response.use(res=>{
  // console.log(res)
  // const router = useRouter();
  if(res.data.code==70001||res.data.code==70002){
    // router.push("/Login");
    ElMessage({
      message: "登录失效，请重新登录",
      type: "error",
      center: true,
    });
    setTimeout(()=>{
      // window.location.href = "/Login"
      location. reload() 
      sessionStorage.removeItem("token")
    },2000)
  }
  return res
},err=>{
  return Promise.reject(err)  //返回错误
})

export default instance;


