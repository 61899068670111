<template>
 <div>
  <router-view/>
 </div>
</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
</style>
