<template>
  <div class="messg">
    <div class="backstage">
      <!-- 按钮 -->
      <div class="dynamic" @click="changer">
        <el-icon v-show="isCollapse === false" color="#ffffff" size="22px"
          ><Expand
        /></el-icon>
        <el-icon v-show="isCollapse === true" color="#ffffff" size="22px"
          ><Fold
        /></el-icon>
      </div>
      <!-- 标题 -->
      <div class="regulator">中台管理系统</div>
    </div>
    <!-- 右侧头像信息 -->
    <div class="offside">
      <!-- <div class="unread">
        <div class="word">
          <el-badge is-dot class="item"
            ><router-link to="/homes"><el-icon color="#ffffff" size="23px"><bell /></el-icon></router-link></el-badge>
          <div class="reminder">
            <div class="angle"></div>
            <div class="exist">你有两条消息未读</div>
          </div>
        </div>
      </div> -->
      <!-- 头像 -->
      <div class="profile">
        <el-avatar :size="40" src="https://empty" @error="errorHandler">
          <img
            src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
          />
        </el-avatar>
      </div>
      <!-- 姓名 -->
      <el-col class="name">
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            admin
            <svg
              style="
                width: 15px;
                color: #ffffff;
                margin-left: 10px;
                padding-bottom: 2px;
              "
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              data-v-ba633cb8=""
            >
              <path fill="currentColor" d="m192 384 320 384 320-384z"></path>
            </svg>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Plus" command="personage"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item :icon="CirclePlusFilled" command="loginout"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { Expand, Fold, Bell } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
// import {quit} from "../../utils/api"
export default {
  setup() {
    const store = useStore();
    const isCollapse = computed(() => store.state.isCollapse);
    const changer = () => {
      store.commit("handleCollapse", !isCollapse.value);
    };
    const visible = ref(false);
    // 用户下拉点击
    const router = useRouter();
    const handleCommand = (command) => {
      if (command == "loginout") {
        // quit().then((res)=>{
        //   console.log(res)
        // })
        sessionStorage.removeItem("token");
        location. reload() 
        router.push("/Login");
      } else if (command == "personage") {
        router.push("/personage");
      }
    };
    return {
      visible,
      changer,
      isCollapse,
      handleCommand,
    };
  },
  components: {
    Expand,
    Fold,
    Bell,
  },
};
</script>
<style lang="scss">
.reminder{
  position: absolute;
  top: 32px;
  display: none;
}
.angle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #303133;
}
.word:hover {
  .reminder {
    display: block;
  }
}
.word {
  position: relative;
  cursor: pointer;
}
.exist {
  position: absolute;
  top: 10px;
  color: #ffffff;
  right: -53px;
  width: 113px;
  background-color: #303133;
  z-index: 2;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
}
.el-badge__content.is-dot {
  border: 1px solid #f56c6c;
  height: 5px;
  width: 5px;
}
.item {
  margin-bottom: 0 !important;
}
.el-dropdown-menu__item {
  padding: 7px 16px;
}
.el-dropdown-menu {
  padding: 10px 0;
}
.name {
  cursor: pointer;
}
.el-dropdown-link {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 20px;
  font-size: 12px;
  padding-top: 3px;
}
.el-col-24 {
  flex: none;
}
.profile {
  padding-top: 5px;
  margin-left: 20px;
}
@media screen and (max-width: 400px) {
  .regulator {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .profile {
    display: none;
  }
  .unread {
    display: none;
  }
  .name {
    display: none;
  }
}
.offside {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.el-popover.my-el-popover {
  background-color: #303133;
  border-color: #303133;
  color: #ffffff;
  text-align: center;
}
.messg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.regulator {
  line-height: 70px;
  font-size: 22px;
  color: #ffffff;
}
.backstage {
  display: flex;
}
.dynamic:hover {
  background-color: #283446;
}
.dynamic {
  width: 64px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>