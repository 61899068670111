import { createRouter, createWebHashHistory } from "vue-router";

import Leftside from "../components/leftside.vue";


let routes = [
  {
    path: "/",
    redirect: "/homepage",
  },
  {
    path: "/",
    name: "Leftside",
    component: Leftside,
    meta: {
      requireAuth: true,
      // keepAlive: false,
    },
    children: [
      {
        path: "/homepage",
        name: "homepage",
        meta: {
          title: "系统首页",
          active:'/homepage',
          // keepAlive: true,
        },
        component: () => import("../views/homepage.vue"),
      },
      {
        path: "/homes",
        name: "homes",
        meta: {
          title: "工会管理",
          active:'/homes',
        },
        component: () => import("../views/homes.vue"),
      },
      {
        path: "/branch",
        name: "branch",
        meta: {
          title: "分工会管理",
          active:'/homes',
        },
        component: () => import("../views/branch.vue"),
      },
      {
        path: "/personage",
        name: "personage",
        meta: {
          title: "修改密码",
          active:'/personage',
        },
        component: () => import("../views/personage.vue"),
      },
      {
        path: "/tourism",
        name: "tourism",
        meta: {
          title: "方案管理",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/tourism.vue"),
      },
      {
        path: "/Addtravel",
        name: "Addtravel",
        meta: {
          title: "方案添加",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/Addtravel.vue"),
      },
      {
        path: "/edittravel",
        name: "edittravel",
        meta: {
          title: "方案编辑",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/edittravel.vue"),
      },
      {
        path: "/classify",
        name: "classify",
        meta: {
          title: "分类管理",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/classify.vue"),
      },
      {
        path: "/Addclassify",
        name: "Addclassify",
        meta: {
          title: "分类添加",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/Addclassify.vue"),
      },
      {
        path: "/editclassify",
        name: "editclassify",
        meta: {
          title: "分类编辑",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/editclassify.vue"),
      },
      {
        path: "/activity",
        name: "activity",
        meta: {
          title: "活动管理",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/activity.vue"),
      },
      {
        path: "/Addactivity",
        name: "Addactivity",
        meta: {
          title: "活动添加",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/Addactivity.vue"),
      },
      {
        path: "/editactivity",
        name: "editactivity",
        meta: {
          title: "活动编辑",
          active:'/tourism',
        },
        component: () => import("../views/CYtourism/editactivity.vue"),
      },
      {
        path: "/JFpublish",
        name: "JFpublish",
        meta: {
          title: "积分发行",
          active:'/JFpublish',
        },
        component: () => import("../views/Welfare/JFpublish.vue"),
      },
      {
        path: "/JFcirculation",
        name: "JFcirculation",
        meta: {
          title: "发行积分",
          active:'/JFpublish',
        },
        component: () => import("../views/Welfare/JFcirculation.vue"),
      },
      {
        path: "/allocating",
        name: "allocating",
        meta: {
          title: "积分分配",
          active:'/allocating',
        },
        component: () => import("../views/Welfare/allocating.vue"),
      },
      {
        path: "/FPintegral",
        name: "FPintegral",
        meta: {
          title: "分配积分",
          active:'/allocating',
        },
        component: () => import("../views/Welfare/FPintegral.vue"),
      },
      {
        path: "/THticket",
        name: "THticket",
        meta: {
          title: "提货券发行",
          active:'/THticket',
        },
        component: () => import("../views/Welfare/THticket.vue"),
      },
      {
        path: "/THissue",
        name: "THissue",
        meta: {
          title: "发行提货券",
          active:'/THticket',
        },
        component: () => import("../views/Welfare/THissue.vue"),
      },
	  {
	    path: "/rewardPoints",
	    name: "rewardPoints",
	    meta: {
	      title: "积分返还",
	      active:'/rewardPoints',
	    },
	    component: () => import("../views/Welfare/rewardPoints.vue"),
	  },
	  {
	    path: "/returnPoints",
	    name: "returnPoints",
	    meta: {
	      title: "返还积分",
	      active:'/rewardPoints',
	    },
	    component: () => import("../views/Welfare/returnPoints.vue"),
	  },
	  {
	    path: "/manageList",
	    name: "manageList",
	    meta: {
	      title: "开发管理",
	      active:'/manageList',
	    },
	    component: () => import("../views/XTmanagement/manageList.vue"),
	  },
	  {
	    path: "/manageAdd",
	    name: "manageAdd",
	    meta: {
	      title: "开发添加",
	      active:'/manageList',
	    },
	    component: () => import("../views/XTmanagement/manageAdd.vue"),
	  },
	  {
	    path: "/manageEdit",
	    name: "manageEdit",
	    meta: {
	      title: "开发编辑",
	      active:'/manageList',
	    },
	    component: () => import("../views/XTmanagement/manageEdit.vue"),
	  },
	  {
	    path: "/manageDetail",
	    name: "manageDetail",
	    meta: {
	      title: "功能查看",
	      active:'/manageList',
	    },
	    component: () => import("../views/XTmanagement/manageDetail.vue"),
	  },
	  {
	    path: "/logsList",
	    name: "logsList",
	    meta: {
	      title: "更新日志管理",
	      active:'/logsList',
	    },
	    component: () => import("../views/XTmanagement/logsList.vue"),
	  },
	  {
	    path: "/logsAdd",
	    name: "logsAdd",
	    meta: {
	      title: "日志添加",
	      active:'/logsList',
	    },
	    component: () => import("../views/XTmanagement/logsAdd.vue"),
	  },
	  {
	    path: "/logsEdit",
	    name: "logsEdit",
	    meta: {
	      title: "日志编辑",
	      active:'/logsList',
	    },
	    component: () => import("../views/XTmanagement/logsEdit.vue"),
	  },
	  {
	    path: "/logsDetail",
	    name: "logsDetail",
	    meta: {
	      title: "更新查看",
	      active:'/logsList',
	    },
	    component: () => import("../views/XTmanagement/logsDetail.vue"),
	  },
	  {
	    path: "/smsPush",
	    name: "smsPush",
	    meta: {
	      title: "短信推送管理",
	      active:'/smsPush',
	    },
	    component: () => import("../views/XTmanagement/smsPush.vue"),
	  },
	  {
	    path: "/smsTemplate",
	    name: "smsTemplate",
	    meta: {
	      title: "短信模板",
	      active:'/smsPush',
	    },
	    component: () => import("../views/XTmanagement/smsTemplate.vue"),
	  },
	  {
	    path: "/templateAdd",
	    name: "templateAdd",
	    meta: {
	      title: "模板添加",
	      active:'/smsTemplate',
	    },
	    component: () => import("../views/XTmanagement/templateAdd.vue"),
	  },
	  {
	    path: "/templateEdit",
	    name: "templateEdit",
	    meta: {
	      title: "模板编辑",
	      active:'/smsTemplate',
	    },
	    component: () => import("../views/XTmanagement/templateEdit.vue"),
	  },
    ],
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
];

let router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  // console.log(from)
  if (to.matched.some((route) => route.meta.requireAuth)) {
    if (sessionStorage.getItem("token")) {
      // 判断用户是否登录，登录之后会存用户相关的信息
      next();
    } else {
      // next({
      //   path: "/Login",
      //   query: { redirect: to.fullPath },
      // });
      if(to.path=='/Login'){ //如果是登录页面路径，就直接next()
        next();
      } else { //不然就跳转到登录；
        next('/Login');
      }
    }
  } else {
    next(); // 确保一定要调用 next()
  }
  if(to.name==='Login'){
    if(sessionStorage.getItem("token")){
      router.push({ path: '/homepage' });
    }
  }
});

export default router;
